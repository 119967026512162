import { fetchCurrentUser } from '../../ducks/user.duck';

import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

import { getGoogleAuthTokens } from '../../util/api';

// ================ Action types ================ //

// ================ Reducer ================ //

// ================ Action creators ================ //

// ================ Thunks ================ //

// export const connectToGoogle = () => {
//   connectGoogle
// }

export const saveGoogleToken = (payload) => (dispatch, getState, sdk) => {
  try {
    getGoogleAuthTokens(payload);
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export const loadData = () => {
  return fetchCurrentUser();
};
